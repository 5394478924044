/**
 * Shows a modal when the user is waiting for their payment to process to discourage them from refreshing or closing the page.
 */
import clsx from "clsx"

import { makeStyles, Theme, Typography } from "@material-ui/core"

import { Dialog } from "app/components/modals"
import { colors, shadows } from "app/theme"

import Loading from "../Loading"

interface StyleProps {}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  actionButton: {
    display: "flex",
    justifyContent: "center",
    fontSize: "15px",
    marginTop: "24px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginBottom: "8px",
    textAlign: "center",
  },
  subtitle: {
    maxWidth: "512px",
    textAlign: "center",
    whiteSpace: "pre-wrap",
    fontWeight: 800,
    color: colors.red[800],
  },
  modal: {
    borderRadius: 12,
    border: "6px solid white",
    boxShadow: shadows["2xl"],
    maxWidth: 524,
    width: "100%",
    backgroundColor: colors.blueGray[50],
  },
  content: {
    padding: "32px 24px",
    gap: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    height: 180,
    borderBottom: `1px solid ${colors.blueGray[200]}`,
    width: "100%",

    [theme.breakpoints.up("xs")]: {
      height: 280,
    },
  },
}))

export interface Props {
  open: boolean
  testId?: string
}

function PaymentProcessingModal({ open, testId = "" }: Props) {
  const styles = useStyles()

  const modalIsOpen = open

  return (
    <Dialog
      data-testid={testId}
      aria-describedby="description"
      aria-labelledby="title"
      disableBackdropClick
      disableEscapeKeyDown
      open={modalIsOpen}
      classes={{
        paper: styles.modal,
      }}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <Loading ariaLabel="payment processing" />
          <Typography
            className={styles.title}
            color="textPrimary"
            id="title"
            variant="h5"
          >
            Your payment is processing...
          </Typography>
          <Typography
            className={clsx("fs-exclude", styles.subtitle)}
            color="textPrimary"
            id="description"
          >
            Please do not refresh or close this page, or it may lead to
            duplicate charges.
          </Typography>
        </div>
      </div>
    </Dialog>
  )
}

export default PaymentProcessingModal
