import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"

import classNames from "classnames"

import { IconButton, Paper, styled, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import * as Popover from "@radix-ui/react-popover"

import HugIcon from "app/assets/icons/settings/hug.svg"
import DesignSystemButton from "app/components/design-system/Button"
import { Dialog } from "app/components/modals"
import useResizeObserver from "app/hooks/use-resize-observer"
import * as Actions from "app/store/actions"
import { colors, navy, primaryColor } from "app/theme"
import makeAppStyles from "app/utils/makeAppStyles"

import BodyText from "../design-system/BodyText"

const useStyles = makeAppStyles((theme) => ({
  accountOwnerSelectionContainer: {
    borderColor: colors.blueGray[300],
    width: "100%",
    display: "flex",
    "flex-grow": 0,
    "flex-direction": "row",
    "justify-content": "space-between",
    "align-items": "center",
    "border-radius": "6px",
    border: `1px solid ${colors.blueGray[300]}`,
    "box-shadow": "0px 1px 2px 0px #0000000D",
    padding: "9px 13px",
    color: navy,
    marginTop: "10px",
  },
  changedAccountOwnerSelectionContainer: {
    borderColor: primaryColor,
    border: `1px solid ${primaryColor}`,
    color: primaryColor,
  },
  errorAccountOwnerSelectionContainer: {
    borderColor: colors.red[500],
  },
  accountOwnerSelectionPopover: {
    backgroundColor: colors.blueGray[50],
  },
}))

const FormContainer = styled("div")({
  width: "100%",
  padding: "0px 20px",
})

const InstructionText = styled("div")({
  width: "100%",
  textAlign: "center",
  padding: "10px",
})

const ActionsContainer = styled("div")({
  display: "flex",
  padding: "12px 20px",
  justifyContent: "flex-end",
})

const TitleAndInfoContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  width: "100%",
  border: "5px 0px 0px 0px",
})

const TitleText = styled("div")({
  width: "100%",
  textAlign: "center",
  padding: "10px",
  fontSize: "19px",
  fontWeight: 600,
  fontFamily: "Josefin Sans",
})

const StyledMuiDialogTitle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flexWrap: !theme.breakpoints.down("sm") ? "wrap" : "nowrap",
  backgroundColor: "white",
  alignItems: "center",
  width: "100%",
  padding: !theme.breakpoints.down("sm") ? "16px 24px" : "8px 24px",
}))

const ClinicOwnerModal = ({ open, onClose, practitioners = [] }) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="team-member-title"
      open={open}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
    >
      <Form onClose={onClose} practitioners={practitioners} />
    </Dialog>
  )
}

export default ClinicOwnerModal

interface PractitionerInfo {
  id: string
  is_clinic_staff: boolean
  user: { full_name: string; email: string }
  clinic: { clinic_owner }
}

function Form({ onClose, practitioners = [] as PractitionerInfo[] }) {
  const dispatch = useDispatch()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedPractitioner, setSelectedPractitioner] = useState<
    PractitionerInfo | undefined
  >(undefined)

  useEffect(() => {
    if (selectedPractitioner) {
      console.log("Selected Practitioner:", selectedPractitioner)
    } else {
      console.log("No practitioner selected")
    }
  }, [selectedPractitioner])

  const handleClose = () => {
    setIsSubmitting(false)
    onClose()
  }

  const formSubmit = () => {
    setIsSubmitting(true)
    dispatch(Actions.setAccountOwner(selectedPractitioner?.id, handleClose))
  }

  return (
    <FormContainer>
      <TitleSection onClose={onClose} />

      <PractitionerAccountOwnerSelection
        practitioner={
          practitioners.find((p) => p.id === p.clinic.clinic_owner) ||
          practitioners[0]
        }
        availablePractitioners={practitioners.filter((p) => !p.is_clinic_staff)}
        onSelect={setSelectedPractitioner}
      />

      <ActionsContainer>
        <DesignSystemButton color="noaction" onClick={onClose} className="mr-3">
          Cancel
        </DesignSystemButton>
        <DesignSystemButton
          variant="contained"
          color="primary"
          type="submit"
          disabled={
            !selectedPractitioner ||
            selectedPractitioner.id === selectedPractitioner.clinic.clinic_owner
          }
          loading={isSubmitting}
          onClick={formSubmit}
        >
          Set Account Owner
        </DesignSystemButton>
      </ActionsContainer>
    </FormContainer>
  )
}

const TitleSection = ({ onClose }) => {
  return (
    <StyledMuiDialogTitle>
      <TitleAndInfoContainer>
        <TitleText>Change Account Owner</TitleText>
      </TitleAndInfoContainer>
    </StyledMuiDialogTitle>
  )
}

function SelectedPractitionerAccountOwner(props: {
  selected: PractitionerInfo | undefined
}) {
  const classes = useStyles()
  return (
    <div>
      <div className={classes.personNameText}>
        {props.selected?.user.full_name}
      </div>
      <div>{props.selected?.user.email}</div>
    </div>
  )
}

function PractitionerAccountOwnerSelection(props: {
  practitioner: PractitionerInfo
  availablePractitioners: PractitionerInfo[]
  onSelect: (selectedPractitioner: PractitionerInfo) => void
}) {
  const classes = useStyles()

  const initialAccountOwner = props.practitioner || undefined
  const [selectedAccountOwner, setSelectedAccountOwner] =
    useState<PractitionerInfo | null>(null)
  const triggerRef = useRef<any>()
  const { width: triggerWidth = "auto" } = useResizeObserver({
    ref: triggerRef,
    box: "border-box",
  })
  const [showContactSelection, setShowContactSelection] = useState(false)
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false)

  const toggleContactSelectionOpen = (isOpen) => {
    // Popover.Root onOpenChange doesn't play nice with blurs of AutoComplete.
    // If the event comes from Popover.Root, it will have an isOpen which we should respect.
    // If not, we should toggle the state.

    // Toggling autocomplete on needs a delay so the popover can find its position
    // before posititioning the autocomplete.

    // If we don't have a value, toggle
    if (isOpen === undefined) {
      const shouldOpen = !showContactSelection
      setShowContactSelection(shouldOpen)
      setTimeout(() => setAutoCompleteOpen(shouldOpen), 1)
    } else {
      // Otherwise, use the value
      setShowContactSelection(isOpen)
      setTimeout(() => setAutoCompleteOpen(isOpen), 1)
    }
  }

  const changedAccountOwnerSelection =
    (!selectedAccountOwner &&
      initialAccountOwner?.id !== props.practitioner.id) ||
    (selectedAccountOwner && selectedAccountOwner?.id !== props.practitioner.id)

  const selectionContainerClasses = classNames(
    classes.accountOwnerSelectionContainer,
    {
      [classes.changedAccountOwnerSelectionContainer]:
        changedAccountOwnerSelection,
    }
  )

  return (
    <div className={classNames(classes.formContainer)}>
      <BodyText>
        <InstructionText>
          This is the person responsible for authorizing and scheduling your
          clinic's move to Fullscript.
        </InstructionText>
        <InstructionText className={classNames(classes.inlineText)}>
          Don't worry, when the time comes, it only takes a few minutes!
        </InstructionText>
      </BodyText>
      <Popover.Root
        open={showContactSelection}
        onOpenChange={toggleContactSelectionOpen}
      >
        <Popover.Trigger
          asChild
          onClick={(e) => {
            e.preventDefault()
          }}
          ref={triggerRef}
        >
          <div
            className={selectionContainerClasses}
            onClick={() => {
              // Leave this undefined. See logic in toggleContactSelectionOpen
              toggleContactSelectionOpen(undefined)
            }}
          >
            <SelectedPractitionerAccountOwner
              selected={selectedAccountOwner || initialAccountOwner}
            />
            <IconButton>
              <img src={HugIcon} alt="Change Selection" />
            </IconButton>
          </div>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className={classes.accountOwnerSelectionPopover}
            style={{
              width: triggerWidth,
              maxWidth: triggerWidth,
              zIndex: 2000,
            }}
          >
            <Autocomplete
              options={props.availablePractitioners}
              getOptionLabel={(option) => option.user.full_name}
              value={selectedAccountOwner || initialAccountOwner}
              open={autoCompleteOpen}
              autoComplete
              disableClearable
              onClose={() => {
                setAutoCompleteOpen(false)
              }}
              onOpen={() => {
                setTimeout(() => setAutoCompleteOpen(true), 1)
              }}
              openOnFocus
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  variant="outlined"
                  defaultValue={""}
                  style={{ zIndex: 2000 }}
                />
              )}
              PaperComponent={(props) => (
                <Paper
                  {...props}
                  style={{
                    ...props.style,
                    marginTop: "0px",
                  }}
                />
              )}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(_, newValue) => {
                if (newValue) {
                  setSelectedAccountOwner(newValue as PractitionerInfo)
                  props.onSelect(newValue)
                  setShowContactSelection(false)
                  setAutoCompleteOpen(false)
                }
              }}
            />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  )
}
