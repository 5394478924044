import * as Sentry from "@sentry/react"

import { API } from "app/api"
import { SplitItInitiateResponse } from "app/types"

export const START_INITIATE_SPLITIT =
  "[PATIENT CHECKOUT: SPLITIT] START INITIATE SPLITIT"
export const SUCCESS_INITIATE_SPLITIT =
  "[PATIENT CHECKOUT: SPLITIT] SUCCESS INITIATE SPLITIT"
export const FAILURE_INITIATE_SPLITIT =
  "[PATIENT CHECKOUT: SPLITIT] FAILURE INITIATE SPLITIT"
export const RECEIVE_SPLITIT_UPDATE =
  "[PATIENT CHECKOUT: SPLITIT] RECEIVE SPLITIT UPDATE"
export const EXISTING_IN_PROGRESS_SPLITIT_PAYMENT_PLAN =
  "[PATIENT CHECKOUT: SPLITIT] EXISTING IN PROGRESS SPLITIT PAYMENT PLAN"

export function initiateSplitIt(checkoutToken: string) {
  return async (dispatch) => {
    dispatch(startInitiateSplitIt())

    try {
      const response = await API.SplitIt.initiate(checkoutToken)
      const data: SplitItInitiateResponse = response.data

      dispatch(successInitiateSplitIt(data))

      return data
    } catch (error: any) {
      Sentry.captureException(error)
      if (error.response && error.response.status === 409) {
        dispatch(existingInProgressSplitItPaymentPlan(error))
      } else {
        dispatch(failureInitiateSplitIt(error))
      }
    }
  }
}

export function startInitiateSplitIt() {
  return {
    type: START_INITIATE_SPLITIT,
  }
}

export function successInitiateSplitIt(data: SplitItInitiateResponse) {
  return {
    type: SUCCESS_INITIATE_SPLITIT,
    payload: data,
  }
}

export function failureInitiateSplitIt(error: any) {
  return {
    type: FAILURE_INITIATE_SPLITIT,
    payload: error,
  }
}

export function existingInProgressSplitItPaymentPlan(error: any) {
  return {
    type: EXISTING_IN_PROGRESS_SPLITIT_PAYMENT_PLAN,
    payload: error,
  }
}

export function receiveSplitItUpdate(update: SplitItInitiateResponse) {
  return {
    type: RECEIVE_SPLITIT_UPDATE,
    payload: update,
  }
}
