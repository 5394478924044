import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"

import clsx from "clsx"
// This restricted import predates our decision to discontinue using formsy-react.
// We now opt to use react-hook-form instead.
// When introducing changes to this component, please consider refactoring to remove
// formsy-react and replace it with react-hook-form where applicable.
// eslint-disable-next-line no-restricted-imports
import Formsy from "formsy-react"
import { toLower, upperFirst } from "lodash"

import * as authActions from "app/auth/store/actions"
import Button from "app/components/design-system/Button"
import { FormTextField } from "app/components/forms/fields"
import useAppSelector from "app/hooks/useAppSelector"
import makeAppStyles from "app/utils/makeAppStyles"

import { LoginProps } from "./LoginTab"

const useStyles = makeAppStyles((theme) => ({
  inputField: {
    "& .MuiInputBase-input": {
      fontSize: 16,
    },
  },
}))

type Props = Pick<
  LoginProps,
  "role" | "toggleForgotPasswordModal" | "initialEmail"
>

const JWTLoginTab: React.FC<Props> = ({
  role,
  toggleForgotPasswordModal,
  initialEmail,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const login = useAppSelector(({ auth }) => auth.login)

  const [isFormValid, setIsFormValid] = useState(false)
  const formRef = useRef<Formsy>(null)

  useEffect(() => {
    if (login?.error && login?.error.password) {
      formRef.current?.updateInputsWithError({
        ...login?.error,
      })
      disableButton()
    }
  }, [login?.error])

  function disableButton() {
    setIsFormValid(false)
  }

  function enableButton() {
    setIsFormValid(true)
  }

  function handleSubmit(model: any) {
    dispatch(authActions.submitLogin(model, role))
  }

  return (
    <div className="w-full">
      <Formsy
        onValidSubmit={handleSubmit}
        onValid={enableButton}
        onInvalid={disableButton}
        ref={formRef}
        className="flex flex-col justify-center w-full"
      >
        <FormTextField
          name="email"
          value={initialEmail ?? ""}
          required
          // label="Email Address"

          className={clsx("mb-5 fs-exclude", classes.inputField)}
          label="Email Address"
        />

        <FormTextField
          className={clsx("mb-5 fs-exclude", classes.inputField)}
          label="Password"
          type="password"
          name="password"
          value=""
          required
          secondaryElement={
            <Button
              color="text"
              onClick={toggleForgotPasswordModal}
              size="small"
              className="mb-1 text-sm"
            >
              Reset Password
            </Button>
          }
        />

        <Button
          color="primary"
          aria-label="LOG IN"
          disabled={!isFormValid}
          type="submit"
        >
          Sign in as {upperFirst(toLower(role))}
        </Button>
      </Formsy>
    </div>
  )
}

export default JWTLoginTab
