/**
 * Only shown when patient already has an in progress splitit payment plan, yet still reaches the order payment page.
 */
import clsx from "clsx"

import { Link, makeStyles, Theme, Typography } from "@material-ui/core"

import { Dialog } from "app/components/modals"
import { colors, shadows } from "app/theme"

interface StyleProps {}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  actionButton: {
    display: "flex",
    justifyContent: "center",
    fontSize: "15px",
    marginTop: "24px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginBottom: "8px",
    textAlign: "center",
  },
  subtitle: {
    maxWidth: "512px",
    textAlign: "center",
    whiteSpace: "pre-wrap",
  },
  modal: {
    borderRadius: 12,
    border: "6px solid white",
    boxShadow: shadows["2xl"],
    maxWidth: 524,
    width: "100%",
    backgroundColor: colors.blueGray[50],
  },
  content: {
    padding: "32px 24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    height: 180,
    borderBottom: `1px solid ${colors.blueGray[200]}`,
    width: "100%",

    [theme.breakpoints.up("xs")]: {
      height: 280,
    },
  },
}))

export interface Props {
  open: boolean
  testId?: string
}

function ExistingInProgressSplitItPaymentPlanModal({
  open,
  testId = "",
}: Props) {
  const styles = useStyles()

  const modalIsOpen = open

  return (
    <Dialog
      data-testid={testId}
      aria-describedby="description"
      aria-labelledby="title"
      disableBackdropClick
      disableEscapeKeyDown
      open={modalIsOpen}
      classes={{
        paper: styles.modal,
      }}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <Typography
            className={styles.title}
            color="textPrimary"
            id="title"
            variant="h5"
          >
            You have a payment plan in progress with SplitIt that needs to be
            confirmed.
          </Typography>
          <Typography
            className={clsx("fs-exclude", styles.subtitle)}
            color="textPrimary"
            id="description"
          >
            Please contact our support team at{" "}
            <Link href="mailto:support@rupahealth.com">
              support@rupahealth.com
            </Link>{" "}
            to complete your order.
          </Typography>
        </div>
      </div>
    </Dialog>
  )
}

export default ExistingInProgressSplitItPaymentPlanModal
