import { useEffect, useMemo } from "react"

import { useFormContext, useController } from "react-hook-form"
import { useDebounce } from "react-use"

import { RadioGroup } from "@material-ui/core"

import { ORDER_LINE_ITEM_TYPE } from "app/constants"
import useFeatureFlag, { FeatureFlag } from "app/hooks/use-feature-flag"
import useQuery from "app/hooks/use-query"
import useAppSelector from "app/hooks/useAppSelector"
import {
  PatientPortalOrder,
  PaymentMethodType,
  SelectedPaymentMethodType,
} from "app/types"

import { FieldNames } from "../../fields"
import { selectIsSplitItInitializedOnce } from "../../store/selectors"
import SplitItPaymentMethod from "./splitit/SplitItPaymentMethod"
import AchPaymentMethod from "./stripe/AchPaymentMethod"
import CardPaymentMethod from "./stripe/CardPaymentMethod"

interface Props {
  order: PatientPortalOrder
  isUpdatingOrder?: boolean
  updateOrder?: (string) => {}
}

const PaymentMethods = ({
  order,
  isUpdatingOrder = false,
  updateOrder,
}: Props) => {
  const { control, clearErrors } = useFormContext()
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control,
    defaultValue: mapSelectedPaymentMethodToPaymentMethodType(
      order.selected_payment_method
    ),
    name: FieldNames.PAYMENT_METHOD_TYPE,
  })

  const query = useQuery()
  const [useAch] = useFeatureFlag(FeatureFlag.AchEnabled)
  const [useSplitIt] = useFeatureFlag(FeatureFlag.SplitItEnabled)
  const [useCustomFeesExpansionFlag] = useFeatureFlag(
    FeatureFlag.CustomFeesExpansion
  )
  const [useAchForCustomFees] = useFeatureFlag(FeatureFlag.AchForCustomFees)
  const useCustomFeesExpansion =
    useCustomFeesExpansionFlag || query.get("custom-fees-expansion") === "1"
  // ACH payments do not work if there are custom fees
  // Disable ACH if the order has a custom fee
  const hasCustomFees = Boolean(
    order.line_items.find(
      (lineItem) => lineItem.type === ORDER_LINE_ITEM_TYPE.CUSTOM_FEE
    )
  )
  const isStorefrontOrder = Boolean(order.storefront_id)
  const isAchEnabled =
    useAch &&
    (!hasCustomFees || (useCustomFeesExpansion && useAchForCustomFees)) &&
    (!isStorefrontOrder || useCustomFeesExpansion)

  const isSplitItEnabled =
    useSplitIt &&
    (!hasCustomFees || useCustomFeesExpansion) &&
    (!isStorefrontOrder || useCustomFeesExpansion)

  useEffect(() => {
    if (error) {
      // clear the payment method error anytime the payment method type is changed
      clearErrors(FieldNames.PAYMENT_METHOD_TYPE)
    }
  }, [value])

  useDebounce(
    () => {
      const selected_payment_method =
        mapPaymentMethodTypeToSelectedPaymentMethod(value)
      if (
        Boolean(order) &&
        order.selected_payment_method !== selected_payment_method
      ) {
        updateOrder?.({ selected_payment_method })
      }
    },
    300,
    [value, Boolean(order)]
  )

  const options = usePaymentMethodOptions(isSplitItEnabled, isAchEnabled)

  return (
    <div className="shadow rounded-lg my-2 border border-gray-300 overflow-hidden">
      <RadioGroup onChange={onChange} value={value}>
        {options.map(({ type, component: PaymentMethodComponent }) => {
          return (
            <PaymentMethodComponent
              key={type}
              active={value === type}
              paymentMethodError={error?.message}
              disabled={isUpdatingOrder}
              type={type}
            />
          )
        })}
      </RadioGroup>
    </div>
  )
}

function mapSelectedPaymentMethodToPaymentMethodType(
  selected_payment_method: SelectedPaymentMethodType | null
): PaymentMethodType {
  switch (selected_payment_method) {
    case SelectedPaymentMethodType.CREDIT_CARD:
      return PaymentMethodType.CREDIT_CARD
    case SelectedPaymentMethodType.DEBIT_CARD:
      return PaymentMethodType.DEBIT_CARD
    case SelectedPaymentMethodType.ACH:
      return PaymentMethodType.ACH
    case SelectedPaymentMethodType.SPLITIT:
      return PaymentMethodType.SPLITIT
    case null:
      return PaymentMethodType.CREDIT_CARD
  }
}

function mapPaymentMethodTypeToSelectedPaymentMethod(paymentMethod) {
  return (function () {
    switch (paymentMethod) {
      case PaymentMethodType.CREDIT_CARD:
        return SelectedPaymentMethodType.CREDIT_CARD
      case PaymentMethodType.DEBIT_CARD:
        return SelectedPaymentMethodType.DEBIT_CARD
      case PaymentMethodType.ACH:
        return SelectedPaymentMethodType.ACH
      case PaymentMethodType.SPLITIT:
        return SelectedPaymentMethodType.SPLITIT
    }
  })()
}

const STRIPE_CREDIT_PAYMENT_METHOD = {
  type: PaymentMethodType.CREDIT_CARD,
  component: CardPaymentMethod,
}
const STRIPE_DEBIT_PAYMENT_METHOD = {
  type: PaymentMethodType.DEBIT_CARD,
  component: CardPaymentMethod,
}
const ACH_PAYMENT_METHOD = {
  type: PaymentMethodType.ACH,
  component: AchPaymentMethod,
}
const SPLITIT_PAYMENT_METHOD = {
  type: PaymentMethodType.SPLITIT,
  component: SplitItPaymentMethod,
}

function usePaymentMethodOptions(
  isSplitItEnabled: boolean,
  isAchEnabled: boolean
) {
  const isSplitItInitializedOnce = useAppSelector(
    selectIsSplitItInitializedOnce
  )
  return useMemo(() => {
    let options = [STRIPE_CREDIT_PAYMENT_METHOD, STRIPE_DEBIT_PAYMENT_METHOD]
    if (isAchEnabled) {
      options.push(ACH_PAYMENT_METHOD)
    }
    if (isSplitItInitializedOnce && isSplitItEnabled) {
      // only include splitit if there were no errors during initialization
      options.push(SPLITIT_PAYMENT_METHOD)
    }
    return options
  }, [isSplitItInitializedOnce])
}

export default PaymentMethods
