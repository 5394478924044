import { useCallback, useRef, useEffect } from "react"
import { useDispatch } from "react-redux"

import { keys, omit, pick } from "lodash"

import * as Sentry from "@sentry/react"

import { API } from "app/api"
import useSplitItHostedFields from "app/main/patient-checkout/hooks/use-splitit-hosted-fields"
import resourceRequest from "app/swr/utils/resource-request"
import {
  PatientCheckoutFormData,
  PatientCheckoutPayload,
  PaymentMethodDetails,
  PaymentMethodType,
} from "app/types"
import { handleApiError, showErrorToast } from "app/utils"

import CheckoutValidationError from "../errors/CheckoutValidationError"
import { isEmptyErrors, normalizeData } from "../utils/checkout-utils"

const createOrderInsurance = async (
  formData: PatientCheckoutFormData,
  orderId: string,
  checkoutToken: string
) => {
  const orderInsuranceInfo = formData.order_insurance
  if (!orderInsuranceInfo) {
    return
  }
  await resourceRequest({
    url: `/order/patient_insurance/`,
    method: "post",
    data: {
      data: {
        type: "patient_insurance",
        attributes: orderInsuranceInfo,
        relationships: {
          order: { data: { id: orderId, type: "order" } },
        },
      },
    },
    params: {
      token: checkoutToken,
    },
  })
}

/**
 * Submits the patient checkout request using the provided form data and payment method details.
 *
 * @param checkoutToken the checkout token string
 * @returns a promise
 */
export default function useSubmitCheckout(
  checkoutToken: string,
  orderId: string,
  orderHasInsuranceTests: boolean
) {
  const dispatch = useDispatch()
  const { hostedFields: splititHostedFields, isPaymentSuccessful } =
    useSplitItHostedFields()

  // Use a ref to track the current value of isPaymentSuccessful so waitForSplititPayment can get current value
  const isPaymentSuccessfulRef = useRef(isPaymentSuccessful)

  useEffect(() => {
    // Only update the ref if the value is null, preventing multiple updates in one payment flow
    if (isPaymentSuccessfulRef.current === null) {
      isPaymentSuccessfulRef.current = isPaymentSuccessful
    }
  }, [isPaymentSuccessful])

  const waitForSplititPayment = useCallback(() => {
    return new Promise((resolve, reject) => {
      const checkStatus = () => {
        if (isPaymentSuccessfulRef.current === true) {
          resolve(true)
        } else if (isPaymentSuccessfulRef.current === false) {
          reject(new Error("Splitit payment failed."))
        }
        // Still processing, check again in a short interval
        else {
          setTimeout(checkStatus, 500)
        }
      }

      checkStatus()
    })
  }, [])

  // A recurring function to verify the splitit payment
  const startVerifyingSplititPayment = useCallback(() => {
    return new Promise((resolve, reject) => {
      let timerId: NodeJS.Timeout
      let attemptCount = 0
      const MAX_ATTEMPTS = 3

      const checkPayment = () => {
        // Stop checking if payment status is already determined
        if (isPaymentSuccessfulRef.current !== null) {
          clearTimeout(timerId)
          return
        }

        attemptCount++

        API.SplitIt.verifyPayment(checkoutToken)
          .then((response) => {
            if (response.data.is_verified) {
              isPaymentSuccessfulRef.current = true
              Sentry.captureMessage(
                `SplitIt FlexForm failed to trigger onSuccess or onError callback, but a payment was successfully verified.`,
                { extra: { checkoutToken } }
              )
              resolve(response.data.status)
            } else if (attemptCount >= MAX_ATTEMPTS) {
              // After 3 failed attempts, set payment as unsuccessful
              isPaymentSuccessfulRef.current = false
              Sentry.captureMessage(
                `SplitIt FlexForm failed to trigger onSuccess or onError callback, and no payment was verified after 3 attempts.`,
                { extra: { checkoutToken } }
              )
              resolve(response.data.status)
            } else {
              // Continue checking if we haven't reached max attempts
              timerId = setTimeout(checkPayment, 10000)
              resolve(response.data.status)
            }
          })
          .catch((error) => {
            // If we fail the API call, we stop checking status and leave it up to FlexForm handle the error.
            reject(error)
            clearTimeout(timerId)
          })
      }

      // Initial delay of 10 seconds before first check
      timerId = setTimeout(checkPayment, 10000)
      return () => clearTimeout(timerId)
    })
  }, [checkoutToken])

  return useCallback(
    async (
      formData: PatientCheckoutFormData,
      paymentMethod?: PaymentMethodDetails
    ) => {
      const payload = createPatientCheckoutPayload(formData, paymentMethod)

      // Initiate the splitit payment using hosted fields instance
      // Usually this is done on the backend, but the new Splitit Hosted Fields
      // requires the payment to be initiated on the client side.
      // So the backend splitit_payment_provider doesn't need to initiate the payment.
      if (paymentMethod?.type === PaymentMethodType.SPLITIT) {
        if (isPaymentSuccessful === null) {
          try {
            splititHostedFields.pay()
            // Wait for the payment to complete before proceeding
            startVerifyingSplititPayment()
            await waitForSplititPayment()
          } catch (error) {
            dispatch(
              showErrorToast({
                message:
                  "Error initiating Splitit payment. Please make sure you are using a valid credit card. Debit cards are not supported.",
              })
            )
            throw error
          }
        } else if (isPaymentSuccessful === false) {
          dispatch(
            showErrorToast({
              message: "Splitit payment failed.",
            })
          )
          throw new Error("Splitit payment failed.")
        }
      }

      try {
        if (orderHasInsuranceTests) {
          await createOrderInsurance(formData, orderId, checkoutToken)
        }
        await API.PatientCheckout.post(checkoutToken, normalizeData(payload))
      } catch (error: any) {
        const errorData = error?.response?.data
        const submitErrors = pick(errorData?.errors, keys(formData))

        if (!isEmptyErrors(submitErrors)) {
          throw new CheckoutValidationError(
            "Validation error in checkout submission",
            submitErrors
          )
        } else if (errorData?.errors?.submitError) {
          dispatch(showErrorToast({ message: errorData?.errors?.submitError }))
        } else {
          dispatch(handleApiError(error))
          throw error
        }
      }
    },
    [
      checkoutToken,
      orderId,
      orderHasInsuranceTests,
      splititHostedFields,
      isPaymentSuccessful,
      waitForSplititPayment,
    ]
  )
}

function createPatientCheckoutPayload(
  formData: PatientCheckoutFormData,
  paymentMethod?: PaymentMethodDetails
): PatientCheckoutPayload {
  const payload: PatientCheckoutPayload = {
    ...omit(formData, ["payment_method"]),
  }

  if (paymentMethod) {
    payload.payment_method = { type: paymentMethod.type }

    switch (paymentMethod.type) {
      case PaymentMethodType.CREDIT_CARD:
      case PaymentMethodType.DEBIT_CARD: {
        if (paymentMethod.card) {
          payload.payment_method.card = {
            payment_method_id: paymentMethod.card.payment_method_id,
            funding: paymentMethod.card.funding,
          }
          // @deprecated
          payload.payment_method_id = paymentMethod.card.payment_method_id
        }
        break
      }
      case PaymentMethodType.ACH: {
        if (paymentMethod.ach) {
          payload.payment_method.ach = paymentMethod.ach
        }
        break
      }
    }
  }

  return payload
}
